import React from 'react'

import { Flex, FlexItem, Box, BoxTitleWrapper, BoxTitle } from "./Base";
import PrimaryButton from "../components/PrimaryButton";


export default function RewardsSection(props) {
    const {rewardByUser, history} = props;
    return (
      <div>
        {
          <Box>
            <BoxTitleWrapper
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <BoxTitle
                style={{
                  fontSize: "0.6rem",
                }}
              >
                Rewards:
              </BoxTitle>
            </BoxTitleWrapper>
            {!rewardByUser ? (
              <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
                <img
                  style={{ maxWidth: "7rem", maxHeight: "7rem" }}
                  src="coin-stacking.gif"
                  alt="coins"
                ></img>
              </div>
            ) : (
              <>
                <div style={{ margin: 20 }}>
                  <Flex style={{ justifyContent: "space-around" }}>
                    <img
                      style={{ maxHeight: "50%", maxWidth: "34%" }}
                      src="/rewards_coin.svg"
                      alt="rewards coin"
                    ></img>
                    <Flex
                      style={{
                        flexDirection: "column",
                        color: "white",
                        fontFamily: "Montserrat",
                      }}
                    >
                      <h2
                        style={{
                          margin: 0,
                          fontWeight: 800,
                          fontSize: "3rem",
                          color: "#F4511E",
                        }}
                      >
                        {rewardByUser[3].totalCoins}
                      </h2>
                      <h6
                        style={{
                          margin: 0,
                          fontSize: "0.8rem",
                        }}
                      >
                        Coins Earned
                      </h6>
                    </Flex>
                  </Flex>
                </div>
                <FlexItem
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <PrimaryButton
                    style={{
                      padding: "10px 83px",
                    }}
                    onClick={() => {
                      history.push("/rewards-cards");
                    }}
                    text={`View More`}
                  ></PrimaryButton>
                </FlexItem>
              </>
            )}
          </Box>
        }
      </div>
    );
}
