import React, { useEffect, useState } from "react";
import TimeAgo from "react-timeago";

import { Flex, FlexItem, Box, BoxTitleWrapper, BoxTitle, DashedBox, SkillTagCap, HorizontalBar,
HorizontalBarIndex, HorizontalBarValue, HorizontalBarWrapper, HorizontalBarBox, SheetBar } from "./Base";
import PrimaryButton from '../components/PrimaryButton';
import MOClient from "../MOClient";

const IncentiveSection = ({ token, history }) => {
  const [target, setTarget] = useState(null);
  const [status, setStatus] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [statusCreatedOn, setStatusCreatedOn] = useState(false);


  useEffect(() => {
    if (token === null) {
      return;
    }
    MOClient.get_incentive_data(token)
      .then((data) => {
        setTarget(data.target);
      })
      .catch((ex) => {
        console.log(ex.toString());
      });
       MOClient.get_generic_headers(token)
         .then((data) => {
           console.log(data);
           if(data !== 'default_headers'){
             setHeaders(data.headers);
           }
         })
         .catch((ex) => {
           console.log(ex.toString());
         });
      MOClient.get_incentive_status(token)
      .then((data) => {
        setStatus(data);
        var n = new Date(data.created_on)
        checkIfCurrentMonthData(n)
      })
      .catch((ex) => {
        console.log(ex.toString());
      });
  }, [token]);

  const calculateTarget = () => {
    if (target > status?.incentive_status) {
      return "100%";
    } else {
      var result =
        (target * 100) /
        (status?.incentive_status > 0 ? status?.incentive_status : 1);
      if (result < 11.5) {
        return `11.5%`;
      } else {
        return `${result}%`;
      }
    }
  };

  const checkIfCurrentMonthData = (receivedDate) => {
    var currDate = new Date();
    if(currDate.getMonth() === receivedDate.getMonth() && currDate.getFullYear() === receivedDate.getFullYear()){
      setStatusCreatedOn(true)
    }else {
     setStatusCreatedOn(false)
    }
  }

  const calculateStatus = () => {
    if (target < status?.incentive_status) {
      return "100%";
    } else {
      var result = (status?.incentive_status * 100) / (target > 0 ? target : 1);
      if (result < 11.5) {
        return `11.5%`;
      } else {
        return `${result}%`;
      }
    }
  };

  const renderIncentiveSection = (
    <div>
      <Box>
        <BoxTitleWrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <BoxTitle
            style={{
              fontSize: "0.6rem",
            }}
          >
            Incentive Status:
          </BoxTitle>
          <BoxTitle
            style={{
              fontSize: "0.6rem",
            }}
          >
            Updated:{" "}
            {
              <TimeAgo
                date={new Date(status?.created_on)}
              />
            }
          </BoxTitle>
        </BoxTitleWrapper>
        <div style={{ marginTop: 30 }}>
          <Flex style={{ justifyContent: "space-around" }}>
            <FlexItem>
              <Flex
                style={{
                  flexDirection: "column",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <h6
                  style={{
                    margin: 0,
                    paddingBottom: "0.5rem",
                  }}
                >
                  {headers ? headers.casa : "CASA Achievements:"}
                </h6>
                <h2
                  style={{
                    margin: 0,
                    fontWeight: 800,
                    fontSize: "2rem",
                  }}
                >
                  {statusCreatedOn ? status?.casa : 0}
                </h2>
                <SheetBar></SheetBar>
              </Flex>
            </FlexItem>
            <FlexItem>
              {" "}
              <Flex
                style={{
                  flexDirection: "column",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <h6
                  style={{
                    margin: 0,
                    paddingBottom: "0.5rem",
                  }}
                >
                  {headers ? headers.td : "TD Achievements:"}
                </h6>
                <h2
                  style={{
                    margin: 0,
                    fontWeight: 800,
                    fontSize: "2rem",
                  }}
                >
                  {statusCreatedOn ? status?.td : 0}
                </h2>
                <SheetBar></SheetBar>
              </Flex>
            </FlexItem>
          </Flex>
          <DashedBox style={{ marginLeft: "-12px", marginRight: "-12px" }}>
            <Flex style={{ width: "100%" }}>
              <FlexItem
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flexBasis: 0,
                  flexGrow: 4,
                  justifyContent: "center",
                }}
              >
                <SkillTagCap>Incentive Status</SkillTagCap>
              </FlexItem>
              <FlexItem
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flexBasis: 0,
                  flexGrow: 8,
                  justifyContent: "center",
                }}
              >
                <HorizontalBarWrapper>
                  <HorizontalBarIndex title={"Target"}>
                    {"Target"}
                  </HorizontalBarIndex>
                  <HorizontalBarBox>
                    {target === "pending" && statusCreatedOn ? (
                      <p
                        style={{
                          margin: 0,
                          fontSize: "0.7rem",
                          color: "white",
                        }}
                      >
                        Pending
                      </p>
                    ) : (
                      <HorizontalBar
                        width={target !== 'pending' ? calculateTarget() : "11.5%"}
                        color="#1e97f4"
                      >
                        <HorizontalBarValue style={{ marginRight: "7px" }}>
                          {target !== 'pending' ? target : 0}
                        </HorizontalBarValue>
                      </HorizontalBar>
                    )}
                  </HorizontalBarBox>
                </HorizontalBarWrapper>

                <HorizontalBarWrapper>
                  <HorizontalBarIndex>Status</HorizontalBarIndex>

                  <HorizontalBarBox>
                    { target === 'pending' && statusCreatedOn ? ( 
                      <p
                        style={{
                          margin: 0,
                          fontSize: "0.7rem",
                          color: "white",
                        }}
                      >
                        Pending
                      </p>
                      ): 
                    <HorizontalBar
                      width={statusCreatedOn ? calculateStatus() : "11.5%"}
                      color="#f4511e"
                    >
                      <HorizontalBarValue style={{ marginRight: "7px" }}>
                        {statusCreatedOn ? status?.incentive_status : 0}
                      </HorizontalBarValue>
                    </HorizontalBar>
                    }
                  </HorizontalBarBox>
                </HorizontalBarWrapper>
              </FlexItem>
            </Flex>
          </DashedBox>

          <FlexItem
            style={{
              justifyContent: "center",
            }}
          >
            <PrimaryButton
              onClick={() => {
                history.push("/incentive-sheets");
              }}
              text={`View Incentive Sheets`}
            ></PrimaryButton>
          </FlexItem>
        </div>
      </Box>
    </div>
  );

  return  renderIncentiveSection;
};

export default IncentiveSection;
