const HOST = "https://analytics.masteroapp.com";
// const HOST = "https://analytics.masteroapp.com";
const ROOT = `${HOST}/user-api`;
const GET_TOKEN_URL = `https://analytics.masteroapp.com/v1/get-token`;

const V1 = `${HOST}/v1`;
const DRIVE_API = `${HOST}/drive-api`
async function generic_get(url, token) {
  const action = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!action.ok) throw new Error("Network error");
  const response = await action.json();
  return response.data || response.rewardByUser;
}

async function get_profile(token) {
  const url = `${ROOT}/me`;
  return generic_get(url, token);
}

async function get_company_meta(token) {
  const url = `${ROOT}/company-meta`;
  return generic_get(url, token);
}

async function get_streak_data(token) {
  const url = `${ROOT}/play-streak`;
  return generic_get(url, token);
}

async function get_training_sessions_history(token) {
  const url = `${ROOT}/training-sessions-history`;
  return generic_get(url, token);
}

async function get_microskill_leaderboard(token, module_id) {
  const url = `${ROOT}/microskill-leaderboard?module=${module_id}`;
  return generic_get(url, token);
}

async function skill_report(token, module_id, options = null) {
  let url = `${ROOT}/skill-report?module=${module_id}`;

  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "&" + u;
  }

  return generic_get(url, token);
}

async function skill_report_historical(token, options = null) {
  let url = `${ROOT}/skill-report-historical`;

  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "?" + u;
  }

  return generic_get(url, token);
}

async function get_performance_metrics(token, options = null) {
  let url = `${ROOT}/performance-metrics`;

  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "?" + u;
  }

  return generic_get(url, token);
}

async function get_incentive_data(token, options = null) {
  let url = `${V1}/incentives`;
  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "?" + u;
  }
  return generic_get(url, token);
}

async function get_incentive_status(token, options = null) {
  let url = `${V1}/get-incentive-status`;
  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "?" + u;
  }
  return generic_get(url, token);
}

async function get_generic_headers(token, options = null) {
  let url = `${V1}/generic-headers`;
  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "?" + u;
  }
  return generic_get(url, token);
}

async function get_incentive_sheets(token, options = null) {
  let url = `${V1}/get-incentive-sheets`;
  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "?" + u;
  }
  return generic_get(url, token);
}

async function get_feature_toggles(token, options = null) {
  let url = `${V1}/get-feature-toggles/leaderboard`;
  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "?" + u;
  }
  return generic_get(url, token);
}

async function get_token(options) {
  const url = GET_TOKEN_URL;

  const result = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: options.email,
      password: options.password,
    }),
  });

  return result;
}

async function get_rewards_data(token, user_id, comp_id, proj_id, options = null) {
  let url = `${DRIVE_API}/get-current-rewards-data/${user_id}/${comp_id}/${proj_id}`;

  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "&" + u;
  }

  return generic_get(url, token);
}

async function get_challenge_lists(
  token,
) {
  let url = `${V1}/get-challenge-lists/`;

  return generic_get(url, token);
}

async function get_top_videos(
  token,
  challenge_id,
  module_id,
  options = null
) {
  let url = `${V1}/get-top-videos?challengeId=${challenge_id}&moduleId=${module_id}`;

  if (options !== null) {
    let u = new URLSearchParams(options).toString();
    url += "&" + u;
  }

  return generic_get(url, token);
}


export default {
  get_profile,
  get_rewards_data,
  get_company_meta,
  get_streak_data,
  get_training_sessions_history,
  get_microskill_leaderboard,
  skill_report,
  skill_report_historical,
  get_token,
  get_performance_metrics,
  get_incentive_data,
  get_incentive_status,
  get_incentive_sheets,
  get_feature_toggles,
  get_generic_headers,
  get_challenge_lists,
  get_top_videos
};
