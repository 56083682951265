import React from 'react'
import { MobilePDFReader } from "reactjs-pdf-reader";

export default function ViewSheet(props) {
    console.log(props)
    const sheet = props.location.state
    console.log(sheet.file_url)
    return (
      <div style={{ overflow: "scroll", height: 600 }}>
        <MobilePDFReader url={sheet.file_url} />
        {/* <PDFViewer
          document={{
            url: sheet.file_url,
          }}
        /> */}
      </div>
    );
}
