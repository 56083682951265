import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const List = styled.div`

`;

const ListItem = styled.div`
  cursor: pointer;
`;

const ListItemText = styled.h5`
  cursor: pointer;
`;

function SimpleDialog({ onClose, selectedValue, open, options, title }) {

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} scroll="paper">
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
      <List>
        {options.map(opt => (
          <ListItem button onClick={() => handleListItemClick(opt.id)} key={opt.id}>
            <ListItemText>{opt.display_text}</ListItemText>
          </ListItem>
        ))}
      </List>
      </DialogContent>
    </Dialog>
  );
}

export default SimpleDialog;
