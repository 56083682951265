import React from "react";
import styles from './PrimaryButton.module.css';

var PrimaryButton = function(props) {

	let onClick = (e) => {
		
		if(props.hasOwnProperty("onClick")) {
			props.onClick(e);
		}
	}

	return (
		<button style={props.style} className={styles.primary_button} onClick={onClick}>{props.text}</button>
	)
};

export default PrimaryButton;