import React, { useEffect, useState } from "react";
import { Box, BoxTitle, BoxTitleWrapper, Flex, FlexItem } from "./Base";
import SimpleDialog from "./SimpleDialog";
import { ReactComponent as ChevronArrow } from "../assets/chevron-arrow.svg";
import MOClient from "../MOClient";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from "react-player";
import { IconButton, makeStyles, Modal } from "@material-ui/core";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilled from "@material-ui/icons/PauseCircleFilled";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Montserrat !important",
    justifyContent: "center",
    padding: "2rem",
    maxWidth: "400px",
    margin: "auto",
    "& video": {
      objectFit: "cover",
      borderRadius: "20px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "1rem 1rem",
    },
  },
  self: {
    fontWeight: "800",
    fontSize: "3rem",
  },
  sub: {
    marginTop: "1rem",
    fontSize: "1.1rem",
  },
  controlsContainer: {
    position: "absolute",
    border: "5px solid #F4511E",
    borderRadius: "50%",
    width: "3rem",
    height: "3rem",
    padding: "1rem",
    left: "37%",
    bottom: "4rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    fontSize: "6.5rem",
    color: "#F4511E",
  },
  button: {
    marginBottom: "1rem",
    padding: "2rem 2rem",
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    background: "#FFB300",
    color: "white",
    flex: "1",
    fontWeight: "600",
    [theme.breakpoints.up("md")]: {
      padding: "1rem 1rem",
    },
  },
  rootContainer: {
    position: "relative",
  },
}));

export default function TopRatedVideo({ token }) {
  const [showDialog, setShowDialog] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [videos, setVideos] = useState([]);
  const [currVideo, setCurrVideo] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  let initialIndex = 0;

  useEffect(() => {
    MOClient.get_challenge_lists(token).then((data) => {
      // console.log(data);
      let chall = [];
      data.map((d) => {
        let obj = {
          id: d.Self_Id,
          display_text: d.Skill_tag,
          moduleId: d.Module_id,
          challengeId: d.Challenge_id,
        };
        chall.push(obj);
      });
      setChallenges(chall);
      setSelectedChallenge({
        moduleId: data[0].Module_id,
        challengeId: data[0].Challenge_id,
      });
    });
  }, []);

  useEffect(() => {
    if (!selectedChallenge) return;
    // console.log(selectedChallenge);
    MOClient.get_top_videos(
      token,
      selectedChallenge.challengeId,
      selectedChallenge.moduleId
    ).then((data) => {
      // console.log("videoData:", data);
      setVideos(data);
    });
  }, [selectedChallenge]);
  // console.log("challenges length for top rated video", challenges, challenges.length);
  return (

    <Box>
      <BoxTitleWrapper>
        <Flex>
          <FlexItem>
            {/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}
          </FlexItem>
          <FlexItem>
            <BoxTitle>Best Practice Role Plays</BoxTitle>
          </FlexItem>
          <FlexItem>
            <span
              onClick={() => setShowDialog(true)}
              style={{ cursor: "pointer" }}
            >
              <ChevronArrow
                onClick={() => {
                  setShowDialog(true);
                }}
                style={{ width: "10px", height: "10px" }}
              ></ChevronArrow>
            </span>
          </FlexItem>
        </Flex>
        <Swiper slidesPerView={2} spaceBetween={30}>
          {videos.map((v, index) => {
            let color;
            if (initialIndex == 0) {
              color =
                "linear-gradient(to top, #0d1880, rgba(126, 126, 110, 0.05) 55%)";
              initialIndex++;
            } else if (initialIndex == 1) {
              color =
                "linear-gradient(to top, #800d3c, rgba(126, 126, 110, 0.05) 55%)";
              initialIndex++;
            } else if (initialIndex == 2) {
              color =
                "linear-gradient(to top, #215607, rgba(126, 126, 110, 0.05) 55%)";
              initialIndex++;
            } else if (initialIndex == 3) {
              initialIndex = 0;
              color =
                "linear-gradient(to top, #0c5187, rgba(126, 126, 110, 0.05) 55%)";
            }
            return (
              <SwiperSlide
                key={`video-${index}`}
                onClick={() => {
                  setCurrVideo(v);
                  setOpenModal(true);
                }}
                style={{ position: "relative" }}
              >
                <a
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    background: color,
                  }}
                ></a>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    zIndex: 2,
                    color: "white",
                    fontFamily: "montserrat",
                  }}
                >
                  <h4 style={{ margin: "0.5rem", fontSize: "0.8rem" }}>
                    {v.first_name}
                  </h4>
                  <p style={{ margin: "0.5rem", fontSize: "0.7rem" }}>
                    {v.total_score}
                  </p>
                </div>
                <ReactPlayer
                  // url={`https://self-coaching.s3.ap-south-1.amazonaws.com/${v.media}`}
                  url={`${v.media}`}
                  playing={false}
                  width="100%"
                  height="100%"
                  style={{ marginTop: "1rem" }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <VideoPlayer setOpenModal={setOpenModal} currVideo={currVideo} />
        </Modal>
      </BoxTitleWrapper>
      <SimpleDialog
        title="Select Challenge"
        options={challenges}
        selectedValue={selectedChallenge}
        open={showDialog}
        onClose={(id) => {
          let chal = challenges.filter((c) => c.id == id);
          setSelectedChallenge({
            moduleId: chal[0].moduleId,
            challengeId: chal[0].challengeId,
          });
          setShowDialog(false);
        }}
      ></SimpleDialog>
    </Box>
  );
}

const VideoPlayer = ({ currVideo, setOpenModal }) => {
  const classes = useStyles();
  const [playerStates, setPlayerStates] = React.useState({
    isPlaying: false,
  });

  const handlePlay = () => {
    setPlayerStates({ ...playerStates, isPlaying: true });
  };

  const handlePause = () =>
    setPlayerStates({ ...playerStates, isPlaying: false });
  return (
    <div
      style={{
        borderRadius: "8px",
        height: "100%",
        marginTop: "1rem",
        marginBottom: "1rem",
        position: "relative",
      }}
    >
      <IconButton
        aria-label="close"
        style={{ position: "absolute", right: "0", zIndex: '99' }}
        onClick={() => setOpenModal(false)}
      >
        <CloseIcon
          onClick={() => setOpenModal(false)}
          style={{ color: "#F4511E" }}
        />
      </IconButton>
      <ReactPlayer
        url={currVideo?.media}
        playing={playerStates.isPlaying}
        width="100%"
        height="100%"
        style={{ borderRadius: "5px", background: '#00000073' }}
        onEnded={() => setPlayerStates({ ...playerStates, isPlaying: false })}
      />
      <div className={classes.rootContainer}>
        <div className={classes.controlsContainer}>
          <IconButton>
            {playerStates.isPlaying ? (
              <PauseCircleFilled
                className={classes.icon}
                onClick={handlePause}
              />
            ) : (
              <PlayCircleFilled className={classes.icon} onClick={handlePlay} />
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
};
