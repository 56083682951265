import React from "react";
import {
  Bootstrap,
  SheetBar,
  SheetCard,
  SheetColumn,
  SheetRow,
  SheetSub,
  SheetTitle,
} from "../components/Base";
import MOClient from "../MOClient";

export default function IncentiveSheets({ token, history }) {
  const [sheets, setSheets] = React.useState(null);

  React.useEffect(() => {
    if (token === null) {
      return;
    }
    MOClient.get_incentive_sheets(token)
      .then((data) => {
        setSheets(data);
        console.log(data);
      })
      .catch((ex) => {
        console.log(ex.toString());
      });
  }, [token]);

  return (
    <Bootstrap>
      {sheets?.length !== 0 ? (
        <SheetColumn>
          <SheetRow>
            {sheets?.map((sheet, index) => {
              let month = sheet.file_key
                .split("/")[1]
                .split(" ")[0]
                .slice(0, 3);
              let year = sheet.file_key.split("/")[1].split(" ")[1];
              return (
                <SheetCard
                  key={index}
                  onClick={() => history.push("/view-sheet", sheet)}
                >
                  <SheetColumn>
                    <SheetTitle>{month}</SheetTitle>
                    <SheetTitle>{year}</SheetTitle>
                    <SheetBar></SheetBar>
                    <SheetSub>View Sheet</SheetSub>
                  </SheetColumn>
                </SheetCard>
              );
            })}
          </SheetRow>
        </SheetColumn>
      ) : (
        <h3 style={{ color: "#DDD", textAlign: "center" }}>
          You have no sheets to view..
        </h3>
      )}
    </Bootstrap>
  );
}
