import styled from 'styled-components';

export const Bootstrap = styled.div`
  width: 100%;
  margin: auto;
  background:linear-gradient(180deg, #3B0054 5%, #080037 100%) 0% 0% no-repeat padding-box;
  overflow-y: auto;
  padding: 15px;
  box-sizing: border-box;

  @media (min-width: 720px) {
    width: 320px;
  }

  min-height: 100vh;
`;

export const LearnerName = styled.h3`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 1.532rem;
  color: white;
  margin-top: 15px;
  letter-spacing: -1.1px;
  margin-bottom: 15px;
`;


export const Flex = styled.div`
  display: flex;
  align-items: center;
  ${props => props.justify ? 'justify-content: space-between' : null}
`

export const FlexItem = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
`;

export const Stat = styled.div`
  text-align: center;
  margin-right: 20px;
`;

export const StatDigit = styled.h4`
  font-size: 1rem;
  font-family: 'Montserrat';
  font-weight: bold;
  color: white;
  margin: 0;
  margin-top: 9px;
  
`;

export const StatCaption = styled.h5`
  font-size: 8px;
  font-family: 'Open Sans';
  font-weight: bold;
  color: white;
  margin: 0;
  margin-top: 3px;
`;

export const Box = styled.div`
  background-color: #4F0160;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 8px 8px #00000033;
  margin: 15px 0 0;
  box-sizing: border-box;
  padding: 12px;
`;


export const FlexGrid = styled.div`
  display: flex;
  margin-left: -7px;
  margin-right: -7px;
`;

export const FlexGridBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-left: 7px;
  padding-right: 7px;
`;

export const BoxTitleWrapper = styled.div`
`;

export const BoxTitle = styled.h4`
  font-family: 'Montserrat';
  font-size: 31px;
  font-weight: bold;
  color: #ffffff;
  font-size: 12px;
  margin: 0;
  margin: 0 10px;
`;

export const InsightWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 10px;
`;

export const InsightText = styled.h4`
  font-size: 27px;
  font-family: 'Montserrat';
  font-weight: 900;
  text-align: center;
  color: #ffffff;
  margin: 0;
`

export const InsightCaption = styled.p`
  font-size: 9px;
  font-family: 'Open Sans';
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin: 3px 0 0;
  text-transform: uppercase;
`;

export const InsightChange = styled.p`
  font-size: 10px;
  font-family: 'Open Sans';
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin: 0 0 0 5px;
  text-align: center;
`;

export const Divider = styled.hr`
  width: 30px;
  border: none;
  height: 2px;
  border-radius: 1px;
  margin: 0 auto;
  background-color: ${props => props.color};
`;

export const DashedBox = styled.div`
  height: 67px;
  border: dashed 1px rgba(244,81,30, 0.25);
  box-sizing: border-box;
  margin: 16px 0;
  display: flex;
  background-color: rgba(255,255,255, 0.05);
`;

export const SkillTagCap = styled.h5`
  font-family: 'Open Sans';
  font-size: 7px;
  font-weight: bold;
  color: white;
  margin: 0;
  margin-left: 9px;

`;

export const SkillTag = styled.h4`
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  margin-top: 4px;
  margin-left: 9px;
  margin-right: 10px;
`;

export const HorizontalBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const HorizontalBarIndex = styled.h5`
  font-family: 'Open Sans';
  font-size: 7px;
  color: #ffffff;
  flex-basis: 40px;
  margin-right: 3px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const HorizontalBarBox = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
`;

export const HorizontalBar = styled.div`
  height: 16px;
  width: ${props => props.width};
  background-color: ${props=> props.color};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
`;

export const HorizontalBarValue = styled.h5`
  font-size: 6px;
  margin-right: 2px;
  color: white;
  font-family: 'Montserrat';
`;

export const TrainingWrapper = styled.div`
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  height: 30px;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
`;

export const TrainingHeading = styled.h4`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 8px;
  color: #ffffff;
  margin: 0 0 5px 0;
`;

export const TrainingButton = styled.button`
  background-color: ${props => props.active? '#f4511e' : 'white'};
  color: ${props => props.active? 'white' : '#727272'};
  border: none;
  border-radius: 1px;
  width: 60px;
  height: 12px;
  font-size: 5px;
  font-family: 'Montserrat';
  font-weight: bold;
`;

export const TableHeading = styled.h4`
  font-family: 'Open Sans';
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
`;

export const TableData = styled.h5`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: normal;
  text-align: ${props => props.center ? 'center' : 'left'};
  color: #ffffff;
  margin: 0;
`;

export const TableRow = styled.div`
  margin-left: -12px;
  margin-right: -12px;
  border: ${props => props.active ? 'dashed 1px rgba(244,81,30, 0.65) !important' : 'none'};
  border-bottom: 1px solid rgba(255,255,255,0.3);
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 12px;

`;


export const TableColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.center ? 'center' : 'left'};
`;

export const FigureText = styled.h4`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #f4511e;
  margin: 4px 0;
`;

export const StreakDayWrapper = styled.div`
  margin: auto;
`;

export const StreakDate = styled.h5`
  font-family: 'Open Sans';
  font-size: 6px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  margin: 7px 0;
`;

export const StreakBall = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: ${props => props.active ? '2px solid #f4511e' : '2px solid rgb(255,255,255)'};
  background-color: ${props => props.active ? '#f4511e' : 'transparent'};
`;

export const Ruler = styled.hr`
  width: 100%;
  background-color: rgba(255,255,255,0.3);
  border: none;
  height: 1px;
`;


export const Text = styled.p`
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 500;
  color: white;
  margin: 12px 0;
  line-height: 4px;
`;

export const LegendLabelHr = styled.hr`
  width: 14px;
  height: 2px;
  background-color: ${props => props.color};
  border: none;
  margin-right: 6px;
  margin-left: 15px;
`;

export const LegendLabel = styled.h4`
  font-size: 10px;
  font-family: 'Open Sans';
  color: white;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  background-color: #080037;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SheetCard = styled.div`
  width: 30%;
  height: 8rem;
  background: #4F0160;
  box-shadow: 0px 8px 8px #00000033;
  border-radius: 4px;
  font-family: Montserrat;
  margin-top: 20px;
`
export const SheetRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const SheetColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem;
`
export const SheetTitle = styled.h3`
  margin: 0;
  color: white;
`
export const SheetBar = styled.div`
  width: 70%;
  background: #f4511e;
  margin-top: 0.5rem;
  height: 3px;
`
export const SheetSub = styled.div`
  margin: 0;
  margin-left: 1.5rem;
  margin-top: 2rem;
  color: white;
  font-weight: 400;
  font-size: 0.6rem;
`;