import React from 'react';
import styled from 'styled-components';
import StreakImageSVG from '../assets/mastero-streak.svg';

const StreakCircle = styled.circle`
  stroke-dashoffset: ${props => props.strokeDashoffset};
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

const StreakWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StreakImage = styled.img`
  height: auto;
  position: absolute;
  left: 23%;
  top: 23%;
`;

function Streak({stroke, radius, progress, strokeColor, fill}) {
    
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const offset = circumference - (progress / 100 * circumference);

  return (
    <StreakWrapper>
      <svg height={radius * 2} width={radius * 2} viewbox="0 0 60 60" preserveAspectRatio="xMidYMin slice">
        <g
          fill="none"
          strokeWidth={stroke}
          data-name="Group 4943"
        >
          <StreakCircle
            r={normalizedRadius}
            stroke="#fff"
            cx={radius}
            cy={radius}
          ></StreakCircle>
          <StreakCircle
            r={normalizedRadius}
            stroke={strokeColor}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={offset}
            cx={radius}
            cy={radius}
          ></StreakCircle>
        </g>        
      </svg>
      <StreakImage src={StreakImageSVG} style={{width: '35px', height: 'auto'}}/>
    </StreakWrapper>
  );
}

export default Streak;
