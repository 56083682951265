import React from 'react';
import SimpleDialog from './components/SimpleDialog';
import {
  Bootstrap,
  LearnerName,
  Flex,
  FlexItem,
  Stat,
  StatDigit,
  StatCaption,
  Box,
  FlexGrid,
  FlexGridBox,
  BoxTitleWrapper,
  BoxTitle,
  InsightWrapper,
  InsightText,
  InsightCaption,
  InsightChange,
  Divider,
  DashedBox,
  SkillTagCap,
  SkillTag,
  HorizontalBarWrapper,
  HorizontalBarIndex,
  HorizontalBarBox,
  HorizontalBar,
  HorizontalBarValue,
  TrainingWrapper,
  TrainingHeading,
  TrainingButton,
  TableHeading,
  TableData,
  TableRow,
  TableColumn,
  FigureText,
  StreakDayWrapper,
  StreakDate,
  StreakBall,
  Ruler,
  Text,
  LegendLabelHr,
  LegendLabel,
  LoaderWrapper,
} from './components/Base';
import Loader from './components/Loader';
import PrimaryButton from './components/PrimaryButton';
import MOClient from './MOClient';
//import { ReactComponent as HelpIcon } from './assets/mastero-help-icon.svg';
import { ReactComponent as ChevronArrow } from "./assets/chevron-arrow.svg";
import { ReactComponent as ArrowHigh } from "./assets/mastero-arrow-high.svg";
import { ReactComponent as ArrowLow } from "./assets/mastero-arrow-low.svg";
import Streak2 from "./components/Streak2";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import IncentiveSection from "./components/IncentiveSection";
import IncentiveSheets from "./components/IncentiveSheets";
import ViewSheet from "./components/ViewSheet";
import RewardsSection from "./components/RewardsSection";
import RewardsCards from "./components/RewardsCards";
import TopRatedVideo from "./components/TopRatedVideo";

let leaderboard_long_data = [
  {
    rank: '',
    name: '',
    points: '',
    time: '',
    completion: '',
  },
  {
    rank: '',
    name: '',
    points: '',
    time: '',
    completion: '',
  },
  {
    rank: '',
    name: '',
    points: '',
    time: '',
    completion: '',
  },
];

const StreakData = {
  play_streak: 5,
  previous_streak: 2,
  analysis_text_1: 'Current streak is higher than last by 100%',
  analysis_text_2: 'Keep up the good work!',
  streak_data: [
    {
      id: 1,
      streak_date: '10',
      streak_day: 'S',
      active: false,
    },
    {
      id: 2,
      streak_date: '11',
      streak_day: 'M',
      active: false,
    },
    {
      id: 3,
      streak_date: '12',
      streak_day: 'T',
      active: false,
    },
    {
      id: 4,
      streak_date: '13',
      streak_day: 'W',
      active: true,
    },
    {
      id: 5,
      streak_date: '14',
      streak_day: 'T',
      active: true,
    },
    {
      id: 6,
      streak_date: '15',
      streak_day: 'F',
      active: true,
    },
    {
      id: 7,
      streak_date: '16',
      streak_day: 'S',
      active: true,
    },
    {
      id: 8,
      streak_date: '17',
      streak_day: 'S',
      active: true,
    },
    {
      id: 9,
      streak_date: '18',
      streak_day: 'M',
      active: false,
    },
    {
      id: 10,
      streak_date: '19',
      streak_day: 'T',
      active: false,
    },
    {
      id: 11,
      streak_date: '20',
      streak_day: 'W',
      active: false,
    },
    {
      id: 12,
      streak_date: '21',
      streak_day: 'T',
      active: false,
    },
  ],
};

let default_company_meta = {
  projects: [],
  modules: [],
  challenges: [],
  meta: {
    training_calendar_access: false,
  },
  default_demographic: true,
};

let default_profile_data = {
  first_name: '',
  last_name: '',
  proj_id: '',
  assigned_modules: [],
  stats: {
    timespent: 0,
    rank: '-',
    mastery_points: '-',
    microskills_completed: '-',
    Completion: '-',
    attempts: 0,
    correct_attempts: 0,
    incorrect_attempts: 0,
  },
  batch_analysis: {
    demographic: 'Batch',
    batch_avg_score: 0,
    score_analysis_text: '',
    batch_avg_timespent: 0,
    timespent_analysis_text: '',
    batch_avg_attempts: 0,
    attempts_analysis_text: '',
    batch_avg_accuracy: 0,
    accuracy_analysis_text: '',
  },
};

function App() {
  let [loading, setLoading] = React.useState(true);
  const history = createBrowserHistory();

  // For Testing replace useState to true and put user_token generated from API in postman
  let [logged_in, set_login_status] = React.useState(false);
  let [user_token, set_jwt_token] = React.useState(null);

  // let [logged_in, set_login_status] = React.useState(true);
  // let [user_token, set_jwt_token] = React.useState(
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxNzgyNDEsImlhdCI6MTY0NTQwNTkxNywiZXhwIjoxNjQ2MDEwNzE3fQ.LKEGtqnHZsQ1uoLmo0MVT-rh02Fw84GNUan8KIuBxY4"
  // );

  const [companyMeta, setCompanyMeta] = React.useState(default_company_meta);

  const [profile, setProfile] = React.useState(default_profile_data);
  const [performanceMetrics, setPerformanceMetrics] = React.useState(default_profile_data);
  const [playStreakData, setPlayStreakData] = React.useState(StreakData);
  const [activeDemographic, setActiveDemographic] = React.useState({
    name: 'Batch',
    mapping_key: 'batch_bf',
    default_demographic: true,
  });
  const [rewardByUser, setRewardByUser] = React.useState(null);
  const [showIncentiveSection, setShowIncentiveSection] = React.useState(false);
  const [showRewardSection, setShowRewardSection] = React.useState(false);
  const [demographicOptions, setDemographicOptions] = React.useState([]);

  const [microskillLeaderboard, setMicroskillLeaderboard] = React.useState(leaderboard_long_data);

  const [historicalRetention, setHistoricalRetention] = React.useState([
    { name: 'Week 1', You: 0, Batch: 0 },
    { name: 'Week 2', You: 0, Batch: 0 },
    { name: 'Week 3', You: 0, Batch: 0 },
    { name: 'Week 4', You: 0, Batch: 0 },
    { name: 'Week 5', You: 0, Batch: 0 },
    { name: 'Week 6', You: 0, Batch: 0 },
  ]);

  const [skillReport, setSkillReport] = React.useState([
    {
      skill_name: '',
      you: 0,
      batch: 0,
    },
    {
      skill_name: '',
      you: 0,
      batch: 0,
    },
    {
      skill_name: '',
      you: 0,
      batch: 0,
    },
    {
      skill_name: '',
      you: 0,
      batch: 0,
    },
  ]);

  const [trainingSessions, setTrainingSessions] = React.useState([
    {
      training_name: '',
      active: true,
      status: 'Attending',
    },
    {
      training_name: '',
      active: false,
      status: 'Not Attending',
    },
    {
      training_name: '',
      active: false,
      status: 'Not Attending',
    },
  ]);

  const [showDialog, setShowDialog] = React.useState(false);
  const [showDemographicDialog, setShowDemographicDialog] = React.useState(false);
  const [showSkillReportDialog, setShowSkillReportDialog] = React.useState(false);
  const [leaderboardModule, setLeaderboardModule] = React.useState(null);
  const [skillReportModule, setSkillReportModule] = React.useState(null);

  // computed values

  let modules = companyMeta.modules.filter((m) => profile.assigned_modules.indexOf(m.id) > -1);
  let leaderboardModuleText = '';
  let skillReportModuleText = '';
  if (leaderboardModule !== null) {
    leaderboardModuleText = modules.filter((m) => m.id === leaderboardModule)[0].name;
  }

  if (skillReportModule !== null) {
    skillReportModuleText = modules.filter((m) => m.id === skillReportModule)[0].name;
  }

  let module_dialog_options = modules.map((m) => ({
    id: m.id,
    display_text: m.name,
  }));
  let demographic_dialog_options = [];

  demographicOptions.forEach((d) => {
    let bad_possibilites = ['emp_id', 'first_name', 'last_name', 'email', 'org_email'];
    if (!bad_possibilites.includes(d.mapping_key)) {
      demographic_dialog_options.push({
        id: d.mapping_key,
        display_text: `You v/s ${d.name}`,
      });
    }
  });

  window.bridge = window.bridge || {};

  window.bridge['messageFromNative'] = function (json_string) {
    let json_obj = JSON.parse(json_string);
    let token = json_obj['user_token_analytics'];
    console.log('got_message', json_string);
    set_jwt_token(token);
    // finally set the login status as true
    set_login_status(true);
  };

  //For iFrame
  window.addEventListener("message", (event) => 
  {
    console.log("Recived Message Event ", event);
    if (event.orign !== "https://leaderboard.masteroapp.com/" || event.orign !== "https://leaderboard-qa.masteroapp.com/") {
      if (event.origin == 'https://web-qa.masteroapp.com' || event.origin == 'https://web-staging.masteroapp.com' || event.origin == 'https://web-prod.masteroapp.com') {
        if (typeof event.data == typeof "string") {
          set_jwt_token(event.data);
          set_login_status(true);
          setLoading(false);
        }
      }
    } else {
      console.log('not my website');
      return;
    }
  });

  //For iOS
  var bridge = require('webview-javascript-bridge-promised');

  //register is the call ObjC makes with javascript with params. 'params' is the message from Objective C
  bridge.registerHandler('masteroCallback', (token, responseCallback) => {
    console.log('registerHandler from JS', token);
    set_jwt_token(token);
    // finally set the login status as true
    set_login_status(true);
    responseCallback('responseCallback from JS!');
  });

  // effects
  React.useEffect(() => {
    // get profile

    if (user_token === null) return;

    setProfile(default_profile_data);

    setCompanyMeta(default_company_meta);

    setLeaderboardModule(null);
    setSkillReportModule(null);

    MOClient.get_company_meta(user_token)
      .then((data) => {
        setCompanyMeta(data);

        // set active demographic

        const demographics = data.meta.demographics;

        setDemographicOptions(demographics);

        let default_demographic_present = false;

        for (let demographic of demographics) {
          if (demographic.hasOwnProperty('default') && demographic.default === true) {
            default_demographic_present = true;
            setActiveDemographic(demographic);
          }
        }

        if (!default_demographic_present) setActiveDemographic(demographics[0]);
      })
      .catch(function (ex) {
        console.log(ex.toString());
      });

    MOClient.get_profile(user_token)
      .then((data) => {
        // console.log(data);
        setProfile(data);
        setLoading(1);
        if (data.comp_id !== 94) return;
        MOClient.get_rewards_data(user_token, data.id, data.comp_id, data.proj_id).then((data) => {
          console.log(data);
          setRewardByUser(data);
        });
      })
      .catch(function (ex) {
        console.log(ex.toString());
      });

    MOClient.get_streak_data(user_token)
      .then((data) => {
        setPlayStreakData(data);
        setLoading(2);
      })
      .catch(function (ex) {
        console.log(ex);
      });

    MOClient.get_training_sessions_history(user_token, null)
      .then((data) => {
        setTrainingSessions(data);
      })
      .catch(function (ex) {
        console.log(ex);
      });
  }, [user_token]);

  React.useEffect(() => {
    if (user_token === null) return;

    if (companyMeta.default_demographic === true) return;
    if (Object.prototype.hasOwnProperty.call(activeDemographic, 'default_demographic')) return;

    let isSubscribed = true;
    MOClient.get_feature_toggles(user_token).then((data) => {
      if (data.action[0].incentive_status === 0 || data.action[0].incentive_status === null) {
        setShowIncentiveSection(false);
      } else {
        setShowIncentiveSection(true);
      }
      if (data.action[0].rewards_recognition === 0 || data.action[0].rewards_recognition === null) {
        setShowRewardSection(false);
      } else {
        setShowRewardSection(true);
      }
    });

    MOClient.skill_report_historical(user_token, {
      demographic: activeDemographic.mapping_key,
    })
      .then((data) => {
        if (isSubscribed) setHistoricalRetention(data);
      })
      .catch(function (ex) {
        console.log(ex);
      });

    return () => (isSubscribed = false);
  }, [activeDemographic, user_token, companyMeta]);

  React.useEffect(() => {
    if (user_token === null) return;
    if (companyMeta.default_demographic === true) return;
    if (Object.prototype.hasOwnProperty.call(activeDemographic, 'default_demographic')) return;

    let isSubscribed = true;

    MOClient.get_performance_metrics(user_token, {
      demographic: activeDemographic.mapping_key,
    })
      .then((data) => {
        if (isSubscribed) setPerformanceMetrics(data);
      })
      .catch(function (ex) {
        console.log(ex);
      });

    return () => (isSubscribed = false);
  }, [activeDemographic, user_token, companyMeta]);

  React.useEffect(() => {
    if (profile.assigned_modules.length !== 0 && companyMeta.modules.length !== 0) {
      if (leaderboardModule === null) {
        setLeaderboardModule(modules[0].id);
      }

      if (skillReportModule === null) {
        setSkillReportModule(modules[0].id);
      }

      // fetch the module leaderboard
    }
  }, [profile, companyMeta, leaderboardModule, skillReportModule, modules]);

  React.useEffect(() => {
    if (leaderboardModule !== null) {
      MOClient.get_microskill_leaderboard(user_token, leaderboardModule)
        .then((data) => {
          setMicroskillLeaderboard(data);
          // console.log("setMicroskillLeaderboard:", data);
          setLoading(3);
        })
        .catch(function (ex) {
          console.log(ex);
        });
    }
  }, [leaderboardModule, user_token]);

  React.useEffect(() => {
    if (user_token === null) return;
    if (companyMeta.default_demographic === true) return;
    if (Object.prototype.hasOwnProperty.call(activeDemographic, 'default_demographic')) return;

    if (skillReportModule !== null) {
      MOClient.skill_report(user_token, skillReportModule, {
        demographic: activeDemographic.mapping_key,
      })
        .then((data) => {
          setSkillReport(data);
        })
        .catch(function (ex) {
          console.log(ex);
        });
    }
  }, [skillReportModule, user_token, activeDemographic, companyMeta]);

  let show_batch_comparison = true;
  // console.log("Loading the Leaderboard for testing purpose")
  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={(matchProps) => {
            if (!logged_in) {
              return (
                <div>
                  <header>
                    <Bootstrap>
                      <LoaderWrapper
                        style={{
                          zIndex: loading < 3 ? '99999' : -1,
                          display: loading < 3 ? 'flex' : 'none',
                        }}>
                        <Loader></Loader>
                      </LoaderWrapper>
                    </Bootstrap>
                  </header>
                </div>
              );
            }

            return (
              <div>
                <header>
                  <Bootstrap>
                    <LoaderWrapper
                      style={{
                        zIndex: loading < 3 ? '99999' : -1,
                        display: loading < 3 ? 'flex' : 'none',
                      }}>
                      <Loader></Loader>
                    </LoaderWrapper>

                    <Flex justify={true}>
                      <FlexItem>
                        <LearnerName>
                          {profile.first_name} {profile.last_name}
                        </LearnerName>
                      </FlexItem>
                      <FlexItem>
                        <PrimaryButton onClick={() => setShowDemographicDialog(true)} text={`You v/s ${activeDemographic.name}`}></PrimaryButton>
                      </FlexItem>
                    </Flex>
                    {/*Init Stats*/}
                    <Flex>
                      <FlexItem>
                        <Stat>
                          <StatDigit>{profile.stats.rank}</StatDigit>
                          <StatCaption>Rank</StatCaption>
                        </Stat>
                      </FlexItem>
                      <FlexItem>
                        <Stat>
                          <StatDigit>{profile.stats.mastery_points}</StatDigit>
                          <StatCaption>Mastery Points</StatCaption>
                        </Stat>
                      </FlexItem>
                      <FlexItem>
                        <Stat>
                          <StatDigit>
                            {profile.stats.microskills_completed}{companyMeta.company_id == 94 && `/${profile.stats.microskills_launched}`}
                          </StatDigit>
                          <StatCaption>Microskills Completed</StatCaption>
                        </Stat >
                      </FlexItem >

                      <FlexItem>
                        <Stat>
                          <StatDigit>{profile.stats.completion}%</StatDigit>
                          <StatCaption>{companyMeta.company_id == 94 ? 'Challenges Completed' : `Completed`}</StatCaption>
                        </Stat>
                      </FlexItem>
                    </Flex >

                    {/*Leaderboard*/};
                    {
                      companyMeta.company_id === 94 ? null : (
                        <Box>
                          <BoxTitleWrapper style={{ display: 'none' }}>
                            <Flex>
                              <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                              <FlexItem>
                                <BoxTitle>Leaderboard - IRDA Certification:</BoxTitle>
                              </FlexItem>
                              <FlexItem>
                                <ChevronArrow style={{ width: '10px', height: '10px' }}></ChevronArrow>
                              </FlexItem>
                            </Flex>
                          </BoxTitleWrapper>
                          <div style={{ marginTop: 15 }}>
                            <Flex>
                              <FlexItem style={{ marginRight: 17 }}>
                                <Streak2
                                  stroke="3"
                                  strokeColor={'#f4511e'}
                                  fill={'transparent'}
                                  radius="32"
                                  progress={(playStreakData.play_streak * 100) / playStreakData.streak_data.length}></Streak2>
                              </FlexItem>
                              <FlexItem>
                                <div>
                                  <BoxTitle style={{ textAlign: 'center' }}>Current Streak</BoxTitle>
                                  <FigureText>{playStreakData.play_streak}</FigureText>
                                  <BoxTitle style={{ textAlign: 'center' }}>Days</BoxTitle>
                                </div>
                              </FlexItem>

                              <FlexItem>
                                <div>
                                  <BoxTitle style={{ textAlign: 'center' }}>Previous Streak</BoxTitle>
                                  <FigureText>{playStreakData.previous_streak}</FigureText>
                                  <BoxTitle style={{ textAlign: 'center' }}>Days</BoxTitle>
                                </div>
                              </FlexItem>
                            </Flex>

                            <Flex
                              style={{
                                marginLeft: -4.5,
                                marginRight: -4.5,
                                marginTop: 13,
                              }}>
                              {playStreakData.streak_data.map((s) => (
                                <StreakDayWrapper>
                                  <StreakDate>{s.streak_date}</StreakDate>
                                  <StreakBall active={s.active}></StreakBall>
                                  <StreakDate>{s.streak_day}</StreakDate>
                                </StreakDayWrapper>
                              ))}
                            </Flex>

                            <Ruler style={{ marginTop: 10 }} />

                            <Text>{playStreakData.analysis_text_1}</Text>
                            {/*<Text>{playStreakData.analysis_text_2}</Text>*/}
                          </div>
                        </Box>
                      )
                    }
                    {showRewardSection === true ? <RewardsSection {...matchProps} rewardByUser={rewardByUser} token={user_token} /> : null}

                    {showIncentiveSection === true ? <IncentiveSection {...matchProps} token={user_token} /> : null}

                    {/*Leaderboard - Long*/}
                    <Box>
                      <BoxTitleWrapper>
                        <Flex>
                          <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                          <FlexItem>
                            <BoxTitle>Leaderboard - {leaderboardModuleText}:</BoxTitle>
                          </FlexItem>
                          <FlexItem>
                            <span onClick={() => setShowDialog(true)} style={{ cursor: 'pointer' }}>
                              <ChevronArrow
                                onClick={() => {
                                  setShowDialog(true);
                                }}
                                style={{ width: '10px', height: '10px' }}></ChevronArrow>
                            </span>
                          </FlexItem>
                        </Flex>
                      </BoxTitleWrapper>
                      <TableRow style={{ marginTop: 10 }}>
                        <TableColumn center={false} style={{ flexBasis: '8%' }}>
                          <TableHeading>Rank</TableHeading>
                        </TableColumn>
                        <TableColumn style={{ flexBasis: '36%' }}>
                          <TableHeading style={{ marginLeft: 15 }}>Name</TableHeading>
                        </TableColumn>
                        <TableColumn center={true} style={{ flexBasis: '16%' }}>
                          <TableHeading>Points</TableHeading>
                        </TableColumn>
                        <TableColumn center={true} style={{ flexBasis: '20%' }}>
                          <TableHeading>Time</TableHeading>
                        </TableColumn>
                        <TableColumn center={true} style={{ flexBasis: '20%' }}>
                          <TableHeading>Completion</TableHeading>
                        </TableColumn>
                      </TableRow>

                      {microskillLeaderboard.map((r) => (
                        <TableRow active={r.user_id === profile.id}>
                          <TableColumn center={true} style={{ flexBasis: '8%' }}>
                            <TableData center={true}>{r.rank}</TableData>
                          </TableColumn>
                          <TableColumn style={{ flexBasis: '36%' }}>
                            <TableData style={{ marginLeft: 15 }}>{r.name}</TableData>
                          </TableColumn>
                          <TableColumn center={true} style={{ flexBasis: '16%' }}>
                            <TableData center={true}>{r.points}</TableData>
                          </TableColumn>
                          <TableColumn center={true} style={{ flexBasis: '20%' }}>
                            <TableData center={true}>{r.time}</TableData>
                          </TableColumn>
                          <TableColumn center={true} style={{ flexBasis: '20%' }}>
                            <TableData center={true}>{r.completion}%</TableData>
                          </TableColumn>
                        </TableRow>
                      ))}
                    </Box>

                    <TopRatedVideo token={user_token} />

                    {/*Grid*/}
                    <FlexGrid>
                      <FlexGridBox>
                        <Box
                          style={{
                            height: show_batch_comparison ? '190px' : '120px',
                          }}>
                          <BoxTitleWrapper>
                            <Flex style={{ justifyContent: 'center' }}>
                              <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                              <FlexItem>
                                <BoxTitle>Score:</BoxTitle>
                              </FlexItem>
                            </Flex>
                          </BoxTitleWrapper>
                          <InsightWrapper>
                            <InsightText>{profile.stats.mastery_points}</InsightText>
                            <InsightCaption>YOUR SCORE</InsightCaption>
                          </InsightWrapper>
                          <Divider color="#f4511e"></Divider>
                          {show_batch_comparison ? (
                            <div>
                              <InsightWrapper>
                                <InsightText>
                                  {performanceMetrics.batch_analysis.batch_avg_score ? performanceMetrics.batch_analysis.batch_avg_score : 'N/A'}
                                </InsightText>
                                <InsightCaption>{performanceMetrics.batch_analysis.demographic} AVERAGE</InsightCaption>
                              </InsightWrapper>

                              {performanceMetrics.batch_analysis.batch_avg_score ? (
                                <Flex
                                  style={{
                                    marginTop: 17,
                                    justifyContent: 'center',
                                  }}>
                                  <FlexItem>
                                    {performanceMetrics.batch_analysis.score_analysis_text.indexOf('Higher') > -1 ? (
                                      <ArrowHigh style={{ width: '7px', height: '7px' }}></ArrowHigh>
                                    ) : (
                                      <ArrowLow style={{ width: '7px', height: '7px' }}></ArrowLow>
                                    )}
                                  </FlexItem>
                                  <FlexItem>
                                    <InsightChange>You are {performanceMetrics.batch_analysis.score_analysis_text}</InsightChange>
                                  </FlexItem>
                                </Flex>
                              ) : null}
                            </div>
                          ) : null}
                        </Box>
                      </FlexGridBox>
                      <FlexGridBox>
                        <Box
                          style={{
                            height: show_batch_comparison ? '190px' : '120px',
                          }}>
                          <BoxTitleWrapper>
                            <Flex style={{ justifyContent: 'center' }}>
                              <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                              <FlexItem>
                                <BoxTitle>Time:</BoxTitle>
                              </FlexItem>
                            </Flex>
                          </BoxTitleWrapper>
                          <InsightWrapper>
                            <InsightText>{new Date(profile.stats.timespent * 1000).toISOString().slice(11, 19)}</InsightText>
                            <InsightCaption>YOUR TIME</InsightCaption>
                          </InsightWrapper>
                          <Divider color="#00cc2e"></Divider>
                          {show_batch_comparison ? (
                            <div>
                              <InsightWrapper>
                                <InsightText>
                                  {performanceMetrics.batch_analysis.batch_avg_timespent
                                    ? new Date(performanceMetrics.batch_analysis.batch_avg_timespent * 1000).toISOString().slice(11, 19)
                                    : 'N/A'}
                                </InsightText>
                                <InsightCaption>{performanceMetrics.batch_analysis.demographic} AVERAGE</InsightCaption>
                              </InsightWrapper>

                              {performanceMetrics.batch_analysis.batch_avg_timespent ? (
                                <Flex
                                  style={{
                                    marginTop: 17,
                                    justifyContent: 'center',
                                  }}>
                                  <FlexItem>
                                    {performanceMetrics.batch_analysis.timespent_analysis_text.indexOf('Higher') > -1 ? (
                                      <ArrowHigh style={{ width: '7px', height: '7px' }}></ArrowHigh>
                                    ) : (
                                      <ArrowLow style={{ width: '7px', height: '7px' }}></ArrowLow>
                                    )}
                                  </FlexItem>
                                  <FlexItem>
                                    <InsightChange>You are {performanceMetrics.batch_analysis.timespent_analysis_text}</InsightChange>
                                  </FlexItem>
                                </Flex>
                              ) : null}
                            </div>
                          ) : null}
                        </Box>
                      </FlexGridBox>
                    </FlexGrid>;

                    {/*Grid*/}
                    <FlexGrid>
                      <FlexGridBox>
                        <Box
                          style={{
                            height: show_batch_comparison ? '190px' : '120px',
                          }}>
                          <BoxTitleWrapper>
                            <Flex style={{ justifyContent: 'center' }}>
                              <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                              <FlexItem>
                                <BoxTitle>Attempts:</BoxTitle>
                              </FlexItem>
                            </Flex>
                          </BoxTitleWrapper>
                          <InsightWrapper>
                            <InsightText>{performanceMetrics.stats.attempts}</InsightText>
                            <InsightCaption>YOUR ATTEMPTS</InsightCaption>
                          </InsightWrapper>
                          <Divider color="#ffdd00"></Divider>
                          {show_batch_comparison ? (
                            <div>
                              <InsightWrapper>
                                <InsightText>
                                  {performanceMetrics.batch_analysis.batch_avg_attempts ? performanceMetrics.batch_analysis.batch_avg_attempts : 'N/A'}
                                </InsightText>
                                <InsightCaption>{performanceMetrics.batch_analysis.demographic} AVERAGE</InsightCaption>
                              </InsightWrapper>
                              {performanceMetrics.batch_analysis.batch_avg_attempts ? (
                                <Flex
                                  style={{
                                    marginTop: 17,
                                    justifyContent: 'center',
                                  }}>
                                  <FlexItem>
                                    {performanceMetrics.batch_analysis.attempts_analysis_text.indexOf('Higher') > -1 ? (
                                      <ArrowHigh style={{ width: '7px', height: '7px' }}></ArrowHigh>
                                    ) : (
                                      <ArrowLow style={{ width: '7px', height: '7px' }}></ArrowLow>
                                    )}
                                  </FlexItem>
                                  <FlexItem>
                                    <InsightChange>You are {performanceMetrics.batch_analysis.attempts_analysis_text}</InsightChange>
                                  </FlexItem>
                                </Flex>
                              ) : null}
                            </div>
                          ) : null}
                        </Box>
                      </FlexGridBox>
                      <FlexGridBox>
                        <Box
                          style={{
                            height: show_batch_comparison ? '190px' : '120px',
                          }}>
                          <BoxTitleWrapper>
                            <Flex style={{ justifyContent: 'center' }}>
                              <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                              <FlexItem>
                                <BoxTitle>Accuracy:</BoxTitle>
                              </FlexItem>
                            </Flex>
                          </BoxTitleWrapper>
                          <InsightWrapper>
                            <InsightText>
                              {performanceMetrics.stats.correct_attempts
                                ? Math.round((performanceMetrics.stats.correct_attempts * 100) / performanceMetrics.stats.attempts)
                                : '0'}
                              %
                            </InsightText>
                            <InsightCaption>YOUR Accuracy</InsightCaption>
                          </InsightWrapper>
                          <Divider color="#0043b5"></Divider>
                          {show_batch_comparison ? (
                            <div>
                              <InsightWrapper>
                                <InsightText>
                                  {performanceMetrics.batch_analysis.batch_avg_accuracy ? performanceMetrics.batch_analysis.batch_avg_accuracy : 'N/A'}
                                  {performanceMetrics.batch_analysis.batch_avg_accuracy ? '%' : null}
                                </InsightText>
                                <InsightCaption>{performanceMetrics.batch_analysis.demographic} AVERAGE</InsightCaption>
                              </InsightWrapper>
                              {performanceMetrics.batch_analysis.batch_avg_accuracy ? (
                                <Flex
                                  style={{
                                    marginTop: 17,
                                    justifyContent: 'center',
                                  }}>
                                  <FlexItem>
                                    {performanceMetrics.batch_analysis.accuracy_analysis_text.indexOf('Higher') > -1 ? (
                                      <ArrowHigh style={{ width: '7px', height: '7px' }}></ArrowHigh>
                                    ) : (
                                      <ArrowLow style={{ width: '7px', height: '7px' }}></ArrowLow>
                                    )}
                                  </FlexItem>
                                  <FlexItem>
                                    <InsightChange>You are {performanceMetrics.batch_analysis.accuracy_analysis_text}</InsightChange>
                                  </FlexItem>
                                </Flex>
                              ) : null}
                            </div>
                          ) : null}
                        </Box>
                      </FlexGridBox>
                    </FlexGrid>;

                    {/*Retention Analysis*/}
                    <Box>
                      <BoxTitleWrapper>
                        <Flex>
                          <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                          <FlexItem>
                            <BoxTitle>Retention Analysis (Monthly):</BoxTitle>
                          </FlexItem>
                          <FlexItem>{/*<ChevronArrow style={{width: '10px', height: '10px'}}></ChevronArrow>*/}</FlexItem>
                        </Flex>
                        <div>
                          <ResponsiveContainer width="100%" height={200}>
                            <LineChart
                              data={historicalRetention}
                              margin={{
                                top: 20,
                                right: 10,
                                left: -40,
                                bottom: 0,
                              }}>
                              <CartesianGrid stroke="rgba(255,255,255,0.1)" vertical={false} />
                              <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#1e97f4" stopOpacity={0.8} />
                                  <stop offset="45%" stopColor="#1e97f4" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#f63b34" stopOpacity={0.8} />
                                  <stop offset="86%" stopColor="#f63b34" stopOpacity={0} />
                                </linearGradient>
                              </defs>
                              <XAxis
                                dataKey="name"
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 8,
                                  color: 'rgba(255,255,255,0.5)',
                                }}
                              />
                              <YAxis
                                domain={[0, 100]}
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 8,
                                  color: 'rgba(255,255,255,0.5)',
                                }}
                              />
                              <Tooltip />
                              {show_batch_comparison ? (
                                <Line
                                  dot={{ r: 2 }}
                                  activeDot={{ r: 4 }}
                                  type="monotone"
                                  dataKey="Batch"
                                  name={activeDemographic.name}
                                  stroke="#1e97f4"
                                  fill="#1e97f4"
                                />
                              ) : null}

                              <Line dot={{ r: 2 }} activeDot={{ r: 4 }} type="monotone" dataKey="You" stroke="#f63b34" fill="#f63b34" />
                            </LineChart>
                          </ResponsiveContainer>

                          <div style={{ marginTop: 0 }}>
                            <Flex style={{ justifyContent: 'center' }}>
                              {show_batch_comparison ? (
                                <FlexItem>
                                  <LegendLabelHr color="#1e97f4"></LegendLabelHr>
                                </FlexItem>
                              ) : null}

                              {show_batch_comparison ? (
                                <FlexItem>
                                  <LegendLabel>{activeDemographic.name} Average</LegendLabel>
                                </FlexItem>
                              ) : null}

                              <FlexItem>
                                <LegendLabelHr color="#f63b34"></LegendLabelHr>
                              </FlexItem>
                              <FlexItem>
                                <LegendLabel>Your Average</LegendLabel>
                              </FlexItem>
                            </Flex>
                          </div>
                        </div>
                      </BoxTitleWrapper>
                    </Box>;

                    {/*Skill Report*/}
                    <Box>
                      <BoxTitleWrapper style={{ marginBottom: 23 }}>
                        <Flex>
                          <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                          <FlexItem>
                            <BoxTitle>Skill Report - {skillReportModuleText}:</BoxTitle>
                          </FlexItem>
                          <FlexItem>
                            <span onClick={() => setShowSkillReportDialog(true)} style={{ cursor: 'pointer' }}>
                              <ChevronArrow onClick={() => setShowSkillReportDialog(true)} style={{ width: '10px', height: '10px' }}></ChevronArrow>
                            </span>
                          </FlexItem>
                        </Flex>
                      </BoxTitleWrapper>
                      {skillReport.length === 0 ? <Text>No Skill Tags are available for this Module</Text> : null}
                      {skillReport.map((s) => (
                        <DashedBox style={{ marginLeft: '-12px', marginRight: '-12px' }}>
                          <Flex style={{ width: '100%' }}>
                            <FlexItem
                              style={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                flexBasis: 0,
                                flexGrow: 1,
                                justifyContent: 'center',
                              }}>
                              <SkillTagCap>Skill Tag</SkillTagCap>
                              <SkillTag>{s.skill_name}</SkillTag>
                            </FlexItem>
                            <FlexItem
                              style={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                flexBasis: 0,
                                flexGrow: 1,
                                justifyContent: 'center',
                              }}>
                              {show_batch_comparison ? (
                                <HorizontalBarWrapper>
                                  <HorizontalBarIndex title={performanceMetrics.batch_analysis.demographic}>
                                    {performanceMetrics.batch_analysis.demographic}
                                  </HorizontalBarIndex>
                                  <HorizontalBarBox>
                                    <HorizontalBar width={`${s.batch}%`} color="#1e97f4">
                                      <HorizontalBarValue>{s.batch}</HorizontalBarValue>
                                    </HorizontalBar>
                                  </HorizontalBarBox>
                                </HorizontalBarWrapper>
                              ) : null}

                              <HorizontalBarWrapper>
                                {show_batch_comparison ? <HorizontalBarIndex>You</HorizontalBarIndex> : null}

                                <HorizontalBarBox>
                                  <HorizontalBar width={`${s.you}%`} color="#f4511e">
                                    <HorizontalBarValue>{s.you}</HorizontalBarValue>
                                  </HorizontalBar>
                                </HorizontalBarBox>
                              </HorizontalBarWrapper>
                            </FlexItem>
                          </Flex>
                        </DashedBox>
                      ))}
                    </Box>;

                    {/*Training Sessions*/}
                    {
                      companyMeta.meta.hasOwnProperty('training_calendar_access') && companyMeta.meta.training_calendar_access ? (
                        <Box>
                          <BoxTitleWrapper>
                            <Flex>
                              <FlexItem>{/*<HelpIcon style={{width: '10px', height: '10px'}}></HelpIcon>*/}</FlexItem>
                              <FlexItem>
                                <BoxTitle>Training Sessions:</BoxTitle>
                              </FlexItem>
                            </Flex>
                          </BoxTitleWrapper>
                          <div style={{ marginTop: '20px' }}>
                            {trainingSessions.length === 0 ? <Text>You are not a part of any active Training Sessions</Text> : null}
                            {trainingSessions.map((t) => (
                              <TrainingWrapper>
                                <TrainingHeading>{t.training_name}</TrainingHeading>
                                <TrainingButton active={t.active}>{t.status}</TrainingButton>
                              </TrainingWrapper>
                            ))}
                          </div>
                        </Box>
                      ) : null
                    }
                  </Bootstrap >
                  <SimpleDialog
                    title="Select Microskill"
                    options={module_dialog_options}
                    selectedValue={leaderboardModule}
                    open={showDialog}
                    onClose={(id) => {
                      setLeaderboardModule(id);
                      setShowDialog(false);
                    }}></SimpleDialog>
                  <SimpleDialog
                    title="Select Microskill"
                    options={module_dialog_options}
                    selectedValue={skillReportModule}
                    open={showSkillReportDialog}
                    onClose={(id) => {
                      setSkillReportModule(id);
                      setShowSkillReportDialog(false);
                    }}></SimpleDialog>

                  <SimpleDialog
                    title="Select Demographic"
                    options={demographic_dialog_options}
                    selectedValue={activeDemographic}
                    open={showDemographicDialog}
                    onClose={(id) => {
                      setActiveDemographic(id);
                      demographicOptions.forEach((d) => {
                        if (d.mapping_key === id) {
                          setActiveDemographic(d);
                        }
                      });
                      setShowDemographicDialog(false);
                    }}></SimpleDialog>
                </header >
              </div >
            );
          }}
        />
        < Route
          path="/incentive-sheets"
          exact
          render={(matchProps) => {
            return <IncentiveSheets {...matchProps} token={user_token} />;
          }}
        />
        < Route
          path="/rewards-cards"
          exact
          render={(matchProps) => {
            return <RewardsCards {...matchProps} token={user_token} rewardByUser={rewardByUser} />;
          }}
        />
        < Route
          path="/view-sheet"
          exact
          render={(matchProps) => {
            return <ViewSheet {...matchProps} token={user_token} />;
          }}
        />
      </Switch >
    </BrowserRouter >
  );
}

export default App;
