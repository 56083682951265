import React from 'react'
import { Bootstrap } from './Base';

export default function RewardsCards(props) {
    const {rewardByUser} = props;

    function titleCase(string) {
      let sentence = string.toLowerCase().split("_");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }

      return sentence.join(" ");
    }
    return (
      <Bootstrap>
        <div>
          {rewardByUser.map((metric, index) => {
            let bgColor = "red";
            let title = "";
            let metricScore = 0;
            if (index === 0) {
              bgColor = "#1E88E5";
              title = "Evolution";
              Object.entries(metric).map(([key, value]) => {
                if(value.achieved > 0 && value.feature_active !== 0){
                  metricScore +=
                    value.achieved >= (value?.limit
                      ? value.limit
                      : 1)
                      ? (value?.limit
                        ? value.limit
                        : 1)   * value.rewards_coins
                      : value.achieved * value.rewards_coins;
                }
              })
            } else if (index === 1) {
              bgColor = "#00CC2E";
              title = "Engagement";
                Object.entries(metric).map(([key, value]) => {
                  if (value.achieved > 0 && value.feature_active !== 0) {
                    metricScore +=
                      value.achieved >= (value?.limit ? value.limit : 1)
                        ? (value?.limit ? value.limit : 1) * value.rewards_coins
                        : value.achieved * value.rewards_coins;
                  }
                });
            } else if (index === 2) {
              bgColor = "#F4511E";
              title = "Effectiveness";
                 Object.entries(metric).map(([key, value]) => {
                   if (value.achieved > 0 && value.feature_active !== 0) {
                     metricScore +=
                       value.achieved >= (value?.limit ? value.limit : 1)
                         ? (value?.limit ? value.limit : 1) *
                           value.rewards_coins
                         : value.achieved * value.rewards_coins;
                   }
                 });
            } else {
              return;
            }

            return (
              <div style={{ background: bgColor, padding: "1rem", margin: '0.7rem 0rem', borderRadius: '6px', display: 'block' }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: '2rem',
                    fontFamily:'montserrat'
                  }}
                >
                  <h2
                    style={{
                      margin: 0,
                      fontWeight: 800,
                      fontSize: "1.8rem",
                      color: "white",
                    }}
                  >
                    {title}
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color: "white",
                      fontSize: "3rem",
                      fontWeight: "500",
                      margin: 0,
                    }}
                  >
                    <h6
                      style={{
                        color: "white",
                        fontSize: "3rem",
                        fontWeight: "500",
                        margin: 0,
                      }}
                    >
                      {metricScore}
                    </h6>
                    <div style={{
                        fontSize: '0.8rem'
                    }}>Coins Earned</div>
                  </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflowX: 'auto',
                    columnGap: '0.4rem'
                }}>
                {
                    Object.entries(metric).map(([key, value]) => {
                        return (
                          <>
                            {" "}
                            <div
                              style={{
                                border: "1px solid white",
                                borderRadius: "6px",
                                padding: "1rem 0.2rem",
                                flex: "0 0 auto",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "white",
                                flexDirection: "column",
                                textAlign: "center",
                                opacity : value.achieved ? "1" : '0.5',
                                display: value.feature_active ? "flex" : 'none'
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  color: "white",
                                  fontFamily: "montserrat",
                                }}
                              >
                                <img
                                  style={{ maxWidth: "27%" }}
                                  src={`${key}.svg`}
                                  alt="assessment"
                                ></img>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <h6
                                    style={{
                                      fontSize: "1.2rem",
                                      margin: 0,
                                      fontWeight: 900,
                                    }}
                                  >
                                    {" "}
                                    {value?.upper_limit ? value?.achieved > 0 ? '1' : '' : value.achieved > value.limit ? value.limit : value.achieved >0 ? value.achieved : ''}
                                    
                                    <span style={{ fontSize: "1rem" }}>
                                    {value.achieved ? "x" : ''}
                                    </span>{" "}
                                    {value.achieved ? value.rewards_coins : '0'}
                                  </h6>
                                  <div style={{ fontSize: "0.5rem" }}>
                                    Coins Earned
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  fontSize: "0.5rem",
                                  paddingTop: "0.6rem",
                                }}
                              >
                                {titleCase(key)}
                              </div>
                            </div>
                          </>
                        );
                    })
                }
                </div>
              </div>
            );
          })}
        </div>
      </Bootstrap>
    );
}
